
import {
  Component,
  Ref,
  Mixins,
  Prop,
} from 'vue-property-decorator';
import { ErrorHandlereMixin, ValidateMixin } from '@/mixins';
import { TextField } from '@/components';
import { VForm } from '@/types';
import { clone_deep } from '@/utils';
import { InstructorShared } from '@/modules/instructors/shared/types';
import InstructorsSelect from '@/modules/instructors/shared/components/InstructorsSelect.vue';
import { Worktables } from '../types';

@Component({
  components: {
    TextField,
    InstructorsSelect,
  },
})
export default class EditWorktableDialog extends Mixins(ValidateMixin, ErrorHandlereMixin) {
  @Ref()
  readonly form!: VForm;

  @Prop()
  worktable!: Worktables.Worktable;

  form_valid = false;

  get worktable_model() {
    return clone_deep(this.worktable);
  }

  async submit() {
    if (this.form_valid) {
      try {
        this.$emit('submit', {
          id: this.worktable_model.id,
          title: this.worktable_model.title,
          instructors: this.worktable_model.instructors,
        });
      } catch (error) {
        this.handleServerError(error);
      }
    }
  }

  cancel() {
    this.$nextTick().then(() => {
      this.worktable_model.instructors = clone_deep(this.worktable.instructors);
    });
    this.$emit('close', false);
  }

  instructors_change(val: InstructorShared[]) {
    this.worktable_model.instructors = val;
  }
}
