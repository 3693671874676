
import { Component, Mixins } from 'vue-property-decorator';
import { worktables_store, auth_store } from '@/store';
import {
  Table,
  PageTitle,
  SimpleDialog,
} from '@/components';
import { AttractionShared } from '@/modules/attractions/shared/types';
import { Instructor } from '@/types';
import { ErrorHandlereMixin } from '@/mixins';
import EditWorktableDialog from '../components/EditWorktableDialog.vue';
import AddWorktableDialog from '../components/AddWorktableDialog.vue';
import { Worktables } from '../types';

const { state, actions } = worktables_store;

@Component({
  components: {
    EditWorktableDialog,
    Table,
    PageTitle,
    SimpleDialog,
    AddWorktableDialog,
  },
})
export default class WorktablesTable extends Mixins(ErrorHandlereMixin) {
  created() {
    window.Echo.channel(`manifest_database_user.${this.user_id}`).listen('WorktableCreatedEvent', (
      data: {
        socket: unknown,
        model: Worktables.Worktable
      },
    ) => {
      const { model } = data;

      console.log('worktable created');

      if (model) {
        console.log('WorktableCreatedEvent - WorktablesTable.vue', model);
        worktables_store.mutations.ADD_WORKTABLE(model);
      }
    });

    window.Echo.channel(`manifest_database_user.${this.user_id}`).listen('WorktableUpdatedEvent', async (
      data: {
        socket: unknown,
        model: Worktables.Worktable
      },
    ) => {
      const { model } = data;

      if (model) {
        console.log('WorktableUpdatedEvent - WorktablesTable.vue', model);
        worktables_store.mutations.EDIT_WORKTABLE(model);
      }
    });
  }

  async mounted() {
    try {
      await actions.get_worktables();
    } catch (error) {
      this.handleServerError(error);
    }
  }

  edited_item: Worktables.Worktable | null = null;
  deleted_worktable: Worktables.Worktable | null = null;
  is_edit_dialog = false;
  is_delete_dialog = false;
  is_success_delete = false;
  add_worktable_dialog_model = false;

  cols = [
    {
      text: 'Название',
      value: 'title',
    },
    {
      text: 'Аттракционы',
      value: 'attractions',
    },
    {
      text: 'Инструкторы',
      value: 'instructors',
    },
    {
      text: 'Действие',
      value: 'action',
    },
  ];

  get user_id() {
    return auth_store.state.user.id;
  }

  get worktables() {
    return state.worktables;
  }

  set worktables(worktables: Worktables.Worktable[]) {
    this.worktables = worktables;
  }

  get_attractions_names(attractions: AttractionShared[]): string {
    if (!attractions.length) {
      return '-';
    }

    return attractions.map((el) => el.title).join(', ');
  }

  get_instructors_names(instructors: Instructor[]) {
    if (!instructors.length) {
      return '-';
    }

    return instructors.map((el) => el.name).join(', ');
  }

  select_edited_worktable(item: Worktables.Worktable) {
    this.edited_item = { ...item };
    this.is_edit_dialog = true;
  }

  async edit_worktable(payload: Worktables.Worktable) {
    try {
      await worktables_store.actions.edit_worktable(payload);
      this.is_edit_dialog = false;
    } catch (error) {
      this.handleServerError(error);
    }
  }

  async delete_worktable() {
    try {
      if (this.deleted_worktable) {
        await worktables_store.actions.delete_worktable(this.deleted_worktable);

        this.is_success_delete = true;
        this.is_delete_dialog = false;
        this.deleted_worktable = null;
      } else {
        throw new Error('worktable is undefined');
      }
    } catch (error) {
      this.handleServerError(error);
    }
  }

  async add_worktable(payload: Worktables.Worktable) {
    try {
      await actions.create_worktable(payload);

      this.add_worktable_dialog_model = false;
    } catch (error) {
      this.handleServerError(error);
    }

    // this.socket.emit('change_worktables');
  }
}
